<template>
  <v-app id="inspire">
    <overall-header/>
    <v-main class=" lighten-3">
      <v-container>
        <v-row
            align="start"
            justify="space-around"
            style="padding: 5vh"
        >
          <h1>{{ project_title }}</h1>
          <v-btn
              tile
              @click="editProjectLink"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Редактировать проект
          </v-btn>
        </v-row>

        <v-row
            justify="space-between"
        >
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Публичная ссылка</td>
                  <td>
                    <v-icon small v-clipboard="getLink(project_link)">mdi-content-copy</v-icon>
                    <a :href="project_link">{{ project_link }}</a>
                  </td>
                </tr>
                <tr>
                  <td>Архивный</td>
                  <td>
                    <v-simple-checkbox
                        v-model="project_archive"
                        disabled
                    ></v-simple-checkbox>
                  </td>
                </tr>
                <tr>
                  <td>Статус</td>
                  <td>{{ project_status_title }}</td>
                </tr>
                <tr>
                  <td>Дедлайн</td>
                  <td>{{ project_deadline | formatDate }}</td>
                </tr>
                <tr>
                  <td>Дедлайн сбора оценок</td>
                  <td>{{ project_answer_deadline | formatDate }}</td>
                </tr>
                <tr>
                  <td>Файлы</td>
                  <td>
                    <div v-if="project_files.length">
                      <v-chip v-for="f in project_files" class="ma-1" v-bind:key="f.name">
                        <v-icon>mdi-download</v-icon>
                        <a :href="f.file" download="f.name">
                          {{ f.name }}
                        </a>
                      </v-chip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Описание</td>
                  <td v-html="project_description"></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Секция
                  </th>
                  <th class="text-left">
                    Исполнителей
                  </th>
                  <th class="text-left">
                    Вилка
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in project_summary_sections" :key="item.id">
                  <td>{{ item.title }}</td>
                  <td>{{ item.answers_count }}</td>
                  <td><span v-if="item.answers_count > 0">{{ item.cost_summary }} руб.</span></td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-btn @click="downloadItem()">
            <v-icon>mdi-download</v-icon>
            Скачать все предложения
          </v-btn>
        </v-row>
        <v-row justify="space-between">
          <v-col
              cols="12"
              md="12"
          >
            <v-text-field
                v-model="search"
                label="Поиск по предложениям"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
            ></v-text-field>

            <v-data-table
                :headers="project_answers_headers"
                :items="project_answers"
                :search="search"
                item-key="id"
                sort-by="section"
                group-by="section_parent"
                class="elevation-1"
            >
              <template v-slot:item.cost="{ item }">
                {{ item.cost }} руб.
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import OverallHeader from "@/components/OverallHeader";

import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import {mapActions, mapGetters} from "vuex";
import axios from '@/helpers/axiosConfig';

export default {
  name: "CreateProject",
  components: {OverallHeader},

  data: () => ({
    project_deadline: new Date(Date.now()),
    project_deadline_menu: false,

    project_answer_deadline: new Date(Date.now()),
    project_answer_deadline_menu: false,
    project_description: "",
    project_status: '',
    project_status_title: '',
    project_title: "",
    project_link: "",
    project_archive: false,
    project_sections: [],
    project_files: [],
    project_summary_sections: [],
    project_answers: [],
    search: null,
    project_answers_headers: [
      {
        text: 'Раздел',
        value: 'section_parent',
      },
      {
        text: 'Секция',
        value: 'section',
      },
      {
        text: 'Стоимость',
        value: 'cost',
      },
      {
        text: 'Исполнитель',
        value: 'email',
      },
      {
        text: 'Комментарий',
        value: 'comment',
      }
    ]
  }),

  computed: {
    ...mapGetters('statuses', {
      status_items: 'list'
    }),
    ...mapGetters('sectiontypes', {
      section_items: 'list'
    }),
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1
    },
  },

  methods: {
    ...mapActions('statuses', {
      fetchStatuses: 'fetchList'
    }),
    ...mapActions('sectiontypes', {
      fetchSectionTypes: 'fetchList'
    }),
    ...mapActions('projects', {
      fetchProject: 'fetchSingle'
    }),

    loadDataForProject: function() {
      if (this.$route.params.id) {
        let that = this;
        this.fetchProject({id: this.$route.params.id}).then(function(response) {
          that.project_title = response.data.title;

          that.project_title = response.data.title;
          that.project_archive = response.data.archived;
          that.project_status = response.data.status;
          that.project_deadline = response.data.deadline;
          that.project_answer_deadline = response.data.answers_deadline;
          that.project_description = response.data.description;
          that.project_files = response.data.files;
          that.project_summary_sections = response.data.summary_sections;
          that.project_link = response.data.link;
        });
      }
    },

    fetchAnswers: function() {
      let that = this;
      axios.get('/api_v3/project/' + this.$route.params.id + '/answers/').then(function(response) {
        that.project_answers = response.data;
      });
    },

    buildTitle: function() {
      if (this.status_items && this.project_status)
        this.project_status_title = this.status_items.find(x => x.id === this.project_status).title;
    },

    getLink(link) {
      return document.location.origin + link;
    },

    downloadItem() {
      axios.get('/api_v3/project/' + this.$route.params.id + '/download/', {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    editProjectLink() {
      this.$router.push('/project/'+ this.$route.params.id + '/edit')
    }
  },

  watch: {
    status_items: "buildTitle",
    project_status: "buildTitle",
  },

  created() {
    this.fetchStatuses();
    this.fetchSectionTypes();
    this.loadDataForProject();
    this.fetchAnswers();
  },
}
</script>

<style scoped>

</style>